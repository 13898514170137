var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      staticClass: "pa-4 d-flex",
      attrs: { height: "auto", block: "", color: _vm.color, elevation: "3" },
      on: { click: _vm.redirecionar },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex flex-column" },
        [
          _vm.count && _vm.warning
            ? _c(
                "v-badge",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip:right",
                      value: "count",
                      expression: "'count'",
                      arg: "right",
                    },
                  ],
                  attrs: { color: "red", overlap: "", content: _vm.count },
                },
                [
                  _vm.warning
                    ? _c(
                        "v-badge",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip:left",
                              value: "warning",
                              expression: "'warning'",
                              arg: "left",
                            },
                          ],
                          attrs: {
                            color: "orange",
                            overlap: "",
                            content: _vm.warning,
                            left: "true",
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mb-2",
                              attrs: {
                                size: "35",
                                color:
                                  _vm.color === "white" ? "primary" : "white",
                              },
                            },
                            [_vm._v(_vm._s(_vm.icon))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm.count
            ? _c(
                "v-badge",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip:right",
                      value: "count",
                      expression: "'count'",
                      arg: "right",
                    },
                  ],
                  attrs: { color: "red", overlap: "", content: _vm.count },
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mb-2",
                      attrs: {
                        size: "35",
                        color: _vm.color === "white" ? "primary" : "white",
                      },
                    },
                    [_vm._v(_vm._s(_vm.icon))]
                  ),
                ],
                1
              )
            : _vm.warning
            ? _c(
                "v-badge",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip:left",
                      value: "warning",
                      expression: "'warning'",
                      arg: "left",
                    },
                  ],
                  attrs: {
                    color: "orange",
                    overlap: "",
                    content: _vm.warning,
                    left: "true",
                  },
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mb-2",
                      attrs: {
                        size: "35",
                        color: _vm.color === "white" ? "primary" : "white",
                      },
                    },
                    [_vm._v(_vm._s(_vm.icon))]
                  ),
                ],
                1
              )
            : _c(
                "v-icon",
                {
                  staticClass: "mb-2",
                  attrs: {
                    size: "35",
                    color: _vm.color === "white" ? "primary" : "white",
                  },
                },
                [_vm._v(_vm._s(_vm.icon))]
              ),
          _c(
            "span",
            {
              staticClass: "text-capitalize font-weight-regular text-wrap",
              staticStyle: { "font-size": "0.7rem" },
            },
            [_vm._v(_vm._s(_vm.text))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }