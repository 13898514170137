<template>
  <v-form @submit.prevent="submit()">
    <div v-if="metodoNotas === 'NOTA'">
      <v-btn
        small
        :color="avaliacao.color"
        :height="avaliacao.selected ? 50 : 45"
        :width="avaliacao.selected ? 55 : 45"
        v-for="(avaliacao, index) in avaliacaoPorNota"
        :key="'avaliacaoPorNota' + index"
        class="mr-1 white--text"
        @click="select(metodoNotas, avaliacao.value)"
      >
        <span class="mt-1">
          {{ avaliacao.value }}
        </span>
      </v-btn>
    </div>
    <div v-else-if="metodoNotas === 'SATISFACAO'">
      <v-btn
        small
        fab
        elevation="0"
        :color="avaliacao.color"
        :height="avaliacao.selected ? 45 : 40"
        :width="avaliacao.selected ? 45 : 40"
        v-for="(avaliacao, index) in avaliacaoPorSatisfacao"
        :key="'avaliacaoPorSatisfacao' + index"
        :class="{ 'mt-11 mx-5': true, 'texto-insatisfeito': index === 0, 'texto-satisfeito': index === 4 }"
        @click="select(metodoNotas, avaliacao.value)"
      >
        <v-icon>{{ avaliacao.icon }}</v-icon>
      </v-btn>
    </div>
    <p v-if="showMsgError" class="msg-error mb-0">A nota deve ser preenchida</p>
    <div v-if="perguntaPesquisa" class="text-left mt-7">
      <p class="mb-0">{{ perguntaPesquisa }}</p>
      <v-text-field name="proposta" v-model="form.respostaPesquisa" type="text" label="Escreva aqui sua resposta..."></v-text-field>
    </div>
    <v-btn color="primary" type="submit" :block="$vuetify.breakpoint.xs" class="secundaryBtn--text mt-5">
      <span class="mt-1">Salvar</span>
    </v-btn>
  </v-form>
</template>

<script>
import comunicacoesService from "@/services/comunicacoesService";
import { findIndex } from "lodash";

export default {
  components: {},
  data() {
    return {
      form: {
        notaPesquisa: null,
        respostaPesquisa: null,
      },
      showMsgError: false,
      avaliacaoPorNota: [
        {
          value: 0,
          color: "#BA2122",
        },
        {
          value: 1,
          color: "#D4222A",
        },
        {
          value: 2,
          color: "#E55423",
        },
        {
          value: 3,
          color: "#EB6E24",
        },
        {
          value: 4,
          color: "#F7A61D",
        },
        {
          value: 5,
          color: "#FDC729",
        },
        {
          value: 6,
          color: "#ECDB09",
        },
        {
          value: 7,
          color: "#E2E14A",
        },
        {
          value: 8,
          color: "#C2D03B",
        },
        {
          value: 9,
          color: "#AEC93B",
        },
        {
          value: 10,
          color: "#66B353",
        },
      ],
      avaliacaoPorSatisfacao: [
        {
          icon: "mdi-emoticon-cry-outline",
          value: 1,
          color: "#E43D53",
        },
        {
          icon: "mdi-emoticon-sad-outline ",
          value: 2,
          color: "#E43D53",
        },
        {
          icon: "mdi-emoticon-happy-outline",
          value: 3,
          color: "#EDBD3C",
        },
        {
          icon: "mdi-emoticon-outline",
          value: 4,
          color: "#93CC5A",
        },
        {
          icon: "mdi-emoticon-excited-outline",
          value: 5,
          color: "#93CC5A",
        },
      ],
    };
  },
  props: {
    guid: {
      type: String,
    },
    metodoNotas: {
      type: String,
      default: "NOTA",
    },
    perguntaPesquisa: {
      type: String,
    },
  },
  computed: {},
  methods: {
    select(metodoNotas, value) {
      if (metodoNotas === "NOTA") {
        this.avaliacaoPorNota = this.avaliacaoPorNota.map(({ selected, ...keepAttrs }) => keepAttrs);
        const index = findIndex(this.avaliacaoPorNota, { value });
        if (index >= 0) this.avaliacaoPorNota[index].selected = true;
      } else if (metodoNotas === "SATISFACAO") {
        this.avaliacaoPorSatisfacao = this.avaliacaoPorSatisfacao.map(({ selected, ...keepAttrs }) => keepAttrs);
        const index = findIndex(this.avaliacaoPorSatisfacao, { value });
        if (index >= 0) this.avaliacaoPorSatisfacao[index].selected = true;
      }
      this.form.notaPesquisa = value;
      this.validateForm();
    },
    validateForm() {
      const { notaPesquisa } = this.form;
      if (typeof notaPesquisa === "number" && notaPesquisa >= 0 && notaPesquisa <= 10) {
        this.showMsgError = false;
        return true;
      } else {
        this.showMsgError = true;
        return false;
      }
    },
    async submit() {
      const isValid = this.validateForm();
      if (isValid) {
        try {
          await comunicacoesService.incluirAvaliacao(this.guid, this.form);
          this.$root.$snackBar.open({
            color: "success",
            message: "Obrigado pelo feedback!",
          });
          this.$emit('setAvaliacao', this.guid);
        } catch (error) {
          this.$root.$snackBar.open({ color: "error", message: error.message || "Erro ao salvar pesquisa" });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.msg-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.texto-insatisfeito {
  position: relative;
}

.texto-insatisfeito::after {
  content: "Muito\A insatisfeito";
  text-transform: none;
  white-space: pre;
  display: block;
  position: absolute;
  bottom: 100%;
  left: 50%;
  color: #e43d53;
  transform: translateX(-50%);
  line-height: 1.3;
}

.texto-satisfeito {
  position: relative;
}

.texto-satisfeito::after {
  content: "Muito\A satisfeito";
  text-transform: none;
  white-space: pre;
  display: block;
  position: absolute;
  bottom: 100%;
  left: 50%;
  color: #93cc5a;
  transform: translateX(-50%);
  line-height: 1.3;
}
</style>
