var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("the-wave-background", [
        _c("h1", { staticClass: "font-weight-regular white--text title" }, [
          _vm._v(" Olá "),
          _c("b", { staticClass: "text-capitalize" }, [
            _vm._v(
              _vm._s(
                _vm.user.nome.split(" ").slice(0, 1).toString().toLowerCase()
              )
            ),
          ]),
          _vm._v("! "),
        ]),
        _c("p", { staticClass: "white--text subtitle-2" }, [
          _vm._v("Bem vindo(a) ao novo Qualicorp QualiVendas"),
        ]),
      ]),
      _c(
        "v-container",
        { staticClass: "pa-4 pt-0" },
        [
          _c(
            "v-row",
            { staticClass: "mb-0", attrs: { "no-gutters": "" } },
            [
              _c("v-col", [
                _c(
                  "h2",
                  { staticClass: "primary--text title font-weight-regular" },
                  [_vm._v("O que você deseja fazer?")]
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pa-2", attrs: { cols: "6", md: "4" } },
                [
                  _c("base-button-icon-text", {
                    attrs: {
                      "data-test-id": "criar-proposta",
                      icon: "mdi-plus-circle",
                      text: "Criar Proposta",
                      redirecionar: _vm.redirecionarCriaProposta,
                      color: "primary",
                      dark: "",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pa-2", attrs: { cols: "6", md: "4" } },
                [
                  _c("base-button-icon-text", {
                    attrs: {
                      icon: "mdi-file-document",
                      "data-test-id": "acompanhar-proposta",
                      text: "Acompanhar Propostas",
                      redirecionar: _vm.redirecionarPropostas,
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pa-2", attrs: { cols: "6", md: "4" } },
                [
                  _c("base-button-icon-text", {
                    attrs: {
                      icon: "mdi-plus-circle",
                      "data-test-id": "criar-simulacao",
                      redirecionar: _vm.redirecionarCriarSimulacao,
                      text: "Criar Simulação",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pa-2", attrs: { cols: "6", md: "4" } },
                [
                  _c("base-button-icon-text", {
                    attrs: {
                      icon: "mdi-file-document-edit",
                      "data-test-id": "simulacoes",
                      redirecionar: _vm.redirecionarSimulacoes,
                      text: "Simulações",
                    },
                  }),
                ],
                1
              ),
              _vm.possuiLeads
                ? _c(
                    "v-col",
                    { staticClass: "pa-2", attrs: { cols: "6", md: "4" } },
                    [
                      _c("base-button-icon-text", {
                        key: _vm.btnLeads,
                        attrs: {
                          icon: "mdi-account-group",
                          redirecionar: _vm.redirecionarLeads,
                          count: _vm.leads,
                          warning: _vm.alertaLeads,
                          text: "Leads",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                { staticClass: "pa-2", attrs: { cols: "6", md: "4" } },
                [
                  _c("base-button-icon-text", {
                    attrs: {
                      icon: "mdi-calendar-month",
                      "data-test-id": "consultar-vigencia",
                      text: "Consultar Vigência",
                      redirecionar: _vm.redirecionarVigencia,
                    },
                  }),
                ],
                1
              ),
              _vm.exibePlantao
                ? _c(
                    "v-col",
                    { staticClass: "pa-2", attrs: { cols: "6", md: "4" } },
                    [
                      _c("base-button-icon-text", {
                        attrs: {
                          icon: "mdi-account-tie",
                          text: "Plantão de Leads",
                          redirecionar: _vm.redirecionarPlantaoLeads,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                { staticClass: "pa-2", attrs: { cols: "6", md: "4" } },
                [
                  _c("base-button-icon-text", {
                    attrs: {
                      icon: "mdi-share-variant",
                      text: "Envio de Boletos",
                      redirecionar: _vm.redirecionarEnvioBoleto,
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pa-2", attrs: { cols: "6", md: "4" } },
                [
                  _c("base-button-icon-text", {
                    attrs: {
                      icon: "mdi-table-check",
                      text: "Tabelas de Venda",
                      redirecionar: _vm.redirecionarTabelasVenda,
                    },
                  }),
                ],
                1
              ),
              _vm.exibeClassificacao
                ? _c(
                    "v-col",
                    { staticClass: "pa-2", attrs: { cols: "6", md: "4" } },
                    [
                      _c("button-classificacao", {
                        attrs: {
                          classificacao: _vm.classificacao,
                          redirecionar: _vm.redirecionarClassificacao,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.exibeNiky
                ? _c(
                    "v-col",
                    { staticClass: "pa-2", attrs: { cols: "6", md: "4" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "pa-4 d-flex",
                          attrs: {
                            height: "auto",
                            block: "",
                            elevation: "3",
                            color: "#FFFFFF",
                          },
                          on: { click: _vm.redirecionarNiky },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column" },
                            [
                              _c("v-img", {
                                staticStyle: { width: "43.5px" },
                                attrs: {
                                  src: require("@/assets/img/niky/niky_logo_simple.png"),
                                },
                              }),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-capitalize font-weight-bold text-wrap pa-0 pl-0",
                                  staticStyle: { "font-size": "0.7rem" },
                                },
                                [_vm._v("Niky")]
                              ),
                            ],
                            1
                          ),
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c("div", { staticClass: "d-flex flex-column" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-none font-weight-bold pa-0 pl-4",
                                    staticStyle: { "font-size": "0.9rem" },
                                  },
                                  [
                                    _vm._v("Conheça o APP"),
                                    _c("br"),
                                    _vm._v("pagamento!"),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          key: _vm.componentKey,
          attrs: { width: "500" },
          model: {
            value: _vm.showDialogNotif,
            callback: function ($$v) {
              _vm.showDialogNotif = $$v
            },
            expression: "showDialogNotif",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c(
                    "h2",
                    {
                      staticClass:
                        "primary--text mb-2 subtitle-1 font-weight-bold",
                    },
                    [_vm._v("Notificação")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.showDialogNotif = false
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { size: "20" } }, [
                        _vm._v("mdi-close"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-card-text", [
                _c("h2", { staticClass: "text-h5" }, [
                  _vm._v(_vm._s(_vm.notificacaoSelecionada.titulo)),
                ]),
                _c("h3", { staticClass: "text-body-1" }, [
                  _vm._v(_vm._s(_vm.notificacaoSelecionada.resumo)),
                ]),
                _vm.notificacaoSelecionada.arquivos &&
                _vm.notificacaoSelecionada.arquivos.length > 0
                  ? _c("div", { staticClass: "container-image mt-5" }, [
                      _c("img", {
                        attrs: {
                          src: _vm.notificacaoSelecionada.arquivos[0].url,
                          alt: "imagem-notificação",
                        },
                      }),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "v-card-actions",
                { staticClass: "pb-5" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "green",
                        icon: "",
                        loading: _vm.loadingLike,
                        disabled: _vm.loadingDislike,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onClickAvaliar(
                            _vm.notificacaoSelecionada.guid,
                            true
                          )
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-thumb-up-outline")])],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-3",
                      attrs: {
                        color: "red",
                        icon: "",
                        loading: _vm.loadingDislike,
                        disabled: _vm.loadingLike,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onClickAvaliar(
                            _vm.notificacaoSelecionada.guid,
                            false
                          )
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-thumb-down-outline")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "300" },
          model: {
            value: _vm.showDialogConfirm,
            callback: function ($$v) {
              _vm.showDialogConfirm = $$v
            },
            expression: "showDialogConfirm",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c(
                    "h2",
                    {
                      staticClass:
                        "primary--text mb-2 subtitle-1 font-weight-bold",
                    },
                    [_vm._v("Avaliação")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.showDialogConfirm = false
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { size: "20" } }, [
                        _vm._v("mdi-close"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-card-text", [
                _vm._v(" O que você achou dessa notificação? "),
              ]),
              _c(
                "v-card-actions",
                { staticClass: "pb-5" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "green",
                        icon: "",
                        loading: _vm.loadingLike,
                        disabled: _vm.loadingDislike,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onClickAvaliar(
                            _vm.notificacaoSelecionada.guid,
                            true
                          )
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-thumb-up-outline")])],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-3",
                      attrs: {
                        color: "red",
                        icon: "",
                        loading: _vm.loadingDislike,
                        disabled: _vm.loadingLike,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onClickAvaliar(
                            _vm.notificacaoSelecionada.guid,
                            false
                          )
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-thumb-down-outline")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          key: _vm.componentAvaliacaoKey,
          attrs: { width: "650" },
          model: {
            value: _vm.showDialogAvaliacao,
            callback: function ($$v) {
              _vm.showDialogAvaliacao = $$v
            },
            expression: "showDialogAvaliacao",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c(
                    "h2",
                    {
                      staticClass:
                        "primary--text mb-2 subtitle-1 font-weight-bold",
                    },
                    [_vm._v("Pesquisa")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.showDialogAvaliacao = false
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { size: "20" } }, [
                        _vm._v("mdi-close"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c("p", [
                    _vm._v(_vm._s(_vm.avaliacaoSelecionada.textoPesquisa)),
                  ]),
                  _c("custom-avaliacao", {
                    staticClass: "text-center",
                    attrs: {
                      guid: _vm.avaliacaoSelecionada.guid,
                      metodoNotas: _vm.avaliacaoSelecionada.metodoNotas,
                      perguntaPesquisa:
                        _vm.avaliacaoSelecionada.perguntaPesquisa,
                    },
                    on: { setAvaliacao: _vm.setAvaliacao },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }