import { render, staticRenderFns } from "./BaseButtonIconText.vue?vue&type=template&id=55b75d0e"
import script from "./BaseButtonIconText.vue?vue&type=script&lang=js"
export * from "./BaseButtonIconText.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBadge,VBtn,VIcon})


/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('55b75d0e')) {
      api.createRecord('55b75d0e', component.options)
    } else {
      api.reload('55b75d0e', component.options)
    }
    module.hot.accept("./BaseButtonIconText.vue?vue&type=template&id=55b75d0e", function () {
      api.rerender('55b75d0e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/BaseButtonIconText.vue"
export default component.exports