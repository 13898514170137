// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/wave2.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".wave-bg[data-v-4d54f7d8] {\n  --position: -90px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") var(--position), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") calc(var(--position) - 1px);\n  background-repeat: repeat-x;\n  background-size: contain;\n}\n#wave[data-v-4d54f7d8] {\n  position: relative;\n}\n#wave svg[data-v-4d54f7d8] {\n  display: inline-block;\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n#wave .container-w[data-v-4d54f7d8] {\n  display: inline-block;\n  position: relative;\n  width: 100%;\n  padding-bottom: 20vh;\n  vertical-align: middle;\n  overflow: hidden;\n}\n#wave .info-home[data-v-4d54f7d8] {\n  position: absolute;\n  top: 5vh;\n  left: 3vh;\n}\n@media (min-width: 480px) and (max-width: 800px) and (orientation: landscape) {\n#wave .container-w[data-v-4d54f7d8] {\n    padding-bottom: 30vh;\n}\n}\n@media (max-width: 320px) {\n#wave .container-w[data-v-4d54f7d8] {\n    padding-bottom: 35vh;\n}\n}\n@media (min-width: 321px) and (max-width: 400px) {\n#wave .container-w[data-v-4d54f7d8] {\n    padding-bottom: 26.5vh;\n}\n}\n@media (min-width: 401px) and (max-width: 480px) {\n#wave .container-w[data-v-4d54f7d8] {\n    padding-bottom: 26vh;\n}\n}\n@media (min-width: 481px) and (max-width: 769px) {\n#wave .container-w[data-v-4d54f7d8] {\n    padding-bottom: 24vh;\n}\n}\n@media (min-width: 770px) and (max-width: 1024px) {\n#wave .container-w[data-v-4d54f7d8] {\n    padding-bottom: 20vh;\n}\n}\n@media (min-width: 1025px) {\n#wave .container-w[data-v-4d54f7d8] {\n    padding-bottom: 24vh;\n}\n}", ""]);
// Exports
module.exports = exports;
