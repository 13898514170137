<template>
  <v-btn height="99px" block class="pa-4 d-flex" :color="colorBackgroundClassificacao" elevation="3" @click="redirecionar">
    <div class="d-flex flex-column" :style="'align-items: center'">
      <div :style="`color: ${corClassificacao}; height: 20px;`">{{ labelClassificacao }}</div>
        <icon-classificacao :class="classificacao !== '' ? 'circleIcon' : null" :classificacao="classificacao" :cor="corClassificacao" width="100" height="50" />
      <div class="subtitle-2 text-none mt-1" :style="`color: ${corClassificacao};`">Saiba mais!</div>
    </div>

  </v-btn>
</template>

<script>
import IconClassificacao from '@/components/IconClassificacao.vue';

const configClassificacoes = {
  EXPERIENTE: { label: 'EXPERIENTE', cor: '#fff', colorBackground: '#2858FF' },
  ESPECIALISTA: { label: 'ESPECIALISTA', cor: '#fff', colorBackground: '#2046CC' },
  ELITE: { label: 'ELITE', cor: '#fff', colorBackground: '#183599' },
  IMBATIVEL: { label: 'IMBATÍVEL', cor: '#fff', colorBackground: '#102366' },
  PLATINUM: { label: 'PLATINUM', cor: '#fff', colorBackground: '#fff' },
};

export default {
  components: {
    IconClassificacao,
  },
  props: {
    color: {
      type: String,
      default: 'white',
    },
    classificacao: {
      type: String,
      default: '',
      validator(value) {
        return ['', 'EXPERIENTE', 'ESPECIALISTA', 'ELITE', 'IMBATIVEL', 'PLATINUM'].includes(value);
      },
    },
    redirecionar: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    corClassificacao() {
      return configClassificacoes[this.classificacao]?.cor || '';
    },

    labelClassificacao() {
      return configClassificacoes[this.classificacao]?.label || '';
    },
    colorBackgroundClassificacao() {
      return configClassificacoes[this.classificacao]?.colorBackground || 'primary';
    },
  },
};
</script>
<style>
.circleIcon {
  height: 50px;
  width: 50px;
  background-color: #fff;
  border-radius: 50%;
  position: relative;
  display: inline-block;
  cursor: pointer;
}
</style>
