var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "wave" } }, [
    _c("div", { staticClass: "container-w" }, [
      _c(
        "svg",
        {
          attrs: {
            x: "0px",
            y: "0px",
            width: "100%",
            height: "100%",
            viewBox: "0 0 500 151",
            "enable-background": "new 0 0 500 151",
            "xml:space": "preserve",
            preserveAspectRatio: "none",
          },
        },
        [
          _c("path", {
            staticStyle: { stroke: "none" },
            style: { fill: _vm.getColor() },
            attrs: { d: "M0,106.2 C140,170 250,20 500,112 L500,00 L0,0 Z" },
          }),
        ]
      ),
    ]),
    _c("div", { staticClass: "info-home" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }