import api from '@/services/api';
import AppError from '@/utils/appError';

const resource = 'comunicacoes';
const getNotificacoes = async () => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_COMUNICACOES_API_KEY } };
    const { data } = await api.get(`${resource}/notificacoes/corretores`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const atualizarVisualizado = async (guid) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_COMUNICACOES_API_KEY } };
    const { data } = await api.post(`${resource}/notificacoes/${guid}/visualizado`, {}, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const avaliarNotificacao = async (guid, avaliacao) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_COMUNICACOES_API_KEY } };
    const { data } = await api.post(`${resource}/notificacoes/${guid}/avaliacao`, { avaliacao }, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

// Avaliação

const getAvaliacaoCorretor = async () => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_COMUNICACOES_API_KEY } };
    const { data } = await api.get(`${resource}/avaliacao-corretor/corretores`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const atualizarVisualizadoAvaliacao = async (guid) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_COMUNICACOES_API_KEY } };
    const { data } = await api.post(`${resource}/avaliacao-corretor/${guid}/visualizado`, {}, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const incluirAvaliacao = async (guid, { notaPesquisa, respostaPesquisa }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_COMUNICACOES_API_KEY } };
    const { data } = await api.post(`${resource}/avaliacao-corretor/${guid}/avaliacao`, { notaPesquisa, respostaPesquisa }, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

export default {
  getNotificacoes,
  atualizarVisualizado,
  avaliarNotificacao,
  getAvaliacaoCorretor,
  atualizarVisualizadoAvaliacao,
  incluirAvaliacao,
};
