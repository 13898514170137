<template>
  <v-btn height="auto" block class="pa-4 d-flex" :color="color" elevation="3" @click="redirecionar">
    <div class="d-flex flex-column">
      <v-badge v-if="count && warning" color="red" overlap :content="count" v-tooltip:right="'count'">
        <v-badge v-if="warning" color="orange" overlap :content="warning" left="true" v-tooltip:left="'warning'">
          <v-icon size="35" :color="color === 'white' ? 'primary' : 'white'" class="mb-2">{{icon}}</v-icon>
        </v-badge>
      </v-badge>
      <v-badge v-else-if="count" color="red" overlap :content="count" v-tooltip:right="'count'">
          <v-icon size="35" :color="color === 'white' ? 'primary' : 'white'" class="mb-2">{{icon}}</v-icon>
      </v-badge>
      <v-badge v-else-if="warning" color="orange" overlap :content="warning" left="true" v-tooltip:left="'warning'">
          <v-icon size="35" :color="color === 'white' ? 'primary' : 'white'" class="mb-2">{{icon}}</v-icon>
      </v-badge>
      <v-icon v-else size="35" :color="color === 'white' ? 'primary' : 'white'" class="mb-2">{{icon}}</v-icon>
      <span class="text-capitalize font-weight-regular text-wrap" style="font-size:0.7rem">{{text}}</span>
    </div>
  </v-btn>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    color: {
      default: 'white',
    },
    count: {
      type: String,
      required: false,
    },
    redirecionar: {
      default: () => {},
    },
    warning: {
      type: String,
      required: false,
    },
  },
};
</script>

<style>
</style>
