<template>
  <div id="wave">
    <div class="container-w">
      <svg
        x="0px"
        y="0px"
        width="100%"
        height="100%"
        viewBox="0 0 500 151"
        enable-background="new 0 0 500 151"
        xml:space="preserve"
        preserveAspectRatio="none"
      >
        <path
          d="M0,106.2 C140,170 250,20 500,112 L500,00 L0,0 Z"
          style="stroke: none;"
          :style="{ fill:getColor() }"
        ></path>
      </svg>
    </div>
    <div class="info-home">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheWaveBackground",
  methods: {
    getColor() {
      return this.$store.getters.getColor('primary');
    },
  },
};
</script>

<style scoped lang="scss">
.wave-bg {
  --position: -90px;
  background: url("~@/assets/img/wave2.svg") var(--position),
    url("~@/assets/img/wave2.svg") calc(var(--position) - 1px);
  background-repeat: repeat-x;
  background-size: contain;
}

#wave {
  position: relative;
  svg {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
  }
  .container-w {
    display: inline-block;
    position: relative;
    width: 100%;
    padding-bottom: 20vh;
    vertical-align: middle;
    overflow: hidden;
  }
  .info-home {
    position: absolute;
    top: 5vh;
    left: 3vh;
  }

  @media (min-width: 480px) and (max-width:800px) and (orientation: landscape){
    .container-w {
      padding-bottom: 30vh;
    }
  }

  @media (max-width: 320px) {
    .container-w {
      padding-bottom: 35vh;
    }
  }

  @media (min-width: 321px) and (max-width:400px) {
    .container-w {
      padding-bottom: 26.5vh;
    }
  }

  @media (min-width: 401px) and (max-width:480px) {
    .container-w {
      padding-bottom: 26vh;
    }
  }

  @media (min-width: 481px) and (max-width:769px) {
    .container-w {
      padding-bottom: 24vh;
    }
  }

  @media (min-width: 770px) and (max-width:1024px) {
    .container-w {
      padding-bottom: 20vh;
    }
  }

  @media (min-width: 1025px) {
    .container-w {
      padding-bottom: 24vh;
    }
  }
}
</style>
