var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      staticClass: "pa-4 d-flex",
      attrs: {
        height: "99px",
        block: "",
        color: _vm.colorBackgroundClassificacao,
        elevation: "3",
      },
      on: { click: _vm.redirecionar },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex flex-column", style: "align-items: center" },
        [
          _c(
            "div",
            { style: `color: ${_vm.corClassificacao}; height: 20px;` },
            [_vm._v(_vm._s(_vm.labelClassificacao))]
          ),
          _c("icon-classificacao", {
            class: _vm.classificacao !== "" ? "circleIcon" : null,
            attrs: {
              classificacao: _vm.classificacao,
              cor: _vm.corClassificacao,
              width: "100",
              height: "50",
            },
          }),
          _c(
            "div",
            {
              staticClass: "subtitle-2 text-none mt-1",
              style: `color: ${_vm.corClassificacao};`,
            },
            [_vm._v("Saiba mais!")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }